header, footer {
  background-color: #2f81c7;
  margin-bottom: 1rem;
  border-radius: 4px;
}
header {
  margin-top: 4px;
  border-radius: 4px;
}
footer {  
  margin-top: 2rem;
  padding: 1rem 4rem;
  text-align: center;
  color: white;
}
img {
  object-fit: contain;
}
section {
  margin-top: 4rem;
}

.navbar a {
  color: white !important;

}

#intro {
  height: calc(100vh - 100px);
  background-image: url('./img/spray.jpg') !important;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  color: white;
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;  
}

#intro .line-spacer {
    width: 20%;
    margin: 1rem auto;
    border-bottom: 1px solid #fff;
}
#intro .description {
  max-width: 600px;
  margin: 6rem auto;
  font-size: 1.25rem;
}

#intro .content {
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 100%;
  padding: 8rem .75rem;
}

@media (max-width: 768px) {
  #intro .description {
    display: flex;
    font-size: 1rem;
    margin: 2rem auto;
  }
  #intro h1 {
    font-size: 1.5rem;
  }
  
  #intro .description {
    font-size: 1rem;
  }
}

.section-header .content {
  position: relative;
  display: block;
  width: 100%;
  grid-template-rows: 1fr;
  grid-template-columns: 100%;
}
.section-header .inline-content {
  position: relative;
  display: grid;
  width: 100%;
  grid-template-rows: 1fr;
  grid-template-columns: 100%;
}
.section-header .img {
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 200px;
}
.section-header .title {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
}
.section-header .title h2 {
  color: white;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 768px) {
  .section-header .title h2 {
    font-size: 1.5rem;
  }
}

#about .section-header .img {
  background-image: url('./img/aboutus.webp') !important;
}

#about .description {
  padding: 1rem 4rem 4rem;
  text-align: center;
  background-color: rgb(239,241,255);
}
@media (max-width: 768px) {
  #about .description {
    padding: 1rem;
    text-align: left;
  }
}
#services .section-header {
  text-align: center;
  padding-bottom: 2rem;
}

#services .card-body {
  text-align: center;
  background-color: #2f81c7;
  color: white;
  font-size: 1.25rem;
}
.list-of-services {
  background-color: rgb(239,241,255);
  padding: 4rem;
}

@media (max-width: 768px) {
  .list-of-services {
    padding: 1rem;
    text-align: left;
  }
}
.list-of-services h2 {
  text-align: center;
}

#testimony {
  background-color: rgb(239,241,255);
}

#testimony .body p  {
  margin: 4rem;
  font-size: large;
  line-height: 1.41em;
}
@media (max-width: 768px) {
  #testimony .body p  {
    margin: 1rem;
  }
}
.testimony-list {
  text-align: left;
}

.section-heading {
  margin-bottom: 2rem;
}
.btn-theme {
  background: #2f81c7;
}
.App {
  text-align: center;
}

.nav .phone {
  margin-left: 20px;
}
.nav .phone a{
  margin-left: 20px;
  border:thin solid #ccc;
  padding: 2px 10px;
  margin: 11px;
}

.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .row.equal {
    display: flex;
    flex-wrap: wrap;
  }
}

